import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ConfigurationService } from '@core/services';
import { UserClaims } from '@okta/okta-auth-js';
import { firstValueFrom, Observable } from 'rxjs';
import { OktaIntegrationService } from '../../services/okta-integration.service';

@Component({
  selector: 'app-okta-container',
  templateUrl: './okta-container.component.html',
  styleUrls: ['./okta-container.component.scss'],
})
export class OktaContainerComponent implements OnInit {
  @Input() text: string;
  @Output() patronEmmiter: EventEmitter<UserClaims> = new EventEmitter();

  patron: UserClaims;
  isAuthenticated$!: Observable<boolean>;

  private _oktaService = inject(OktaIntegrationService);
  public configurationService = inject(ConfigurationService);

  async ngOnInit(): Promise<void> {
    this.isAuthenticated$ = this._oktaService.getIsAuthenticated();

    if (await firstValueFrom(this.isAuthenticated$)) {
      this.patron = await this._oktaService.getUserInfo();

      this.patronEmmiter.emit(this.patron);
    }
  }

  signInRedirect(): void {
    let origin = window.location.href;
    this._oktaService.signInWithRedirect(origin, 'okta/redirect');
  }

  signOut(): void {
    let origin = window.location.origin;
    this._oktaService.signOut(origin);
  }
}
