import { inject, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState, UserClaims } from '@okta/okta-auth-js';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OktaIntegrationService {
  private _oktaAuth = inject(OKTA_AUTH);
  private _router = inject(Router);
  private _oktaAuthStateService = inject(OktaAuthStateService);

  constructor() {}

  public getIsAuthenticated(): Observable<boolean> {
    return this._oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
  }

  /**
   * Function that will execute the redirection to the okta hosted page to proceed to the user identification.
   * @param {string} origin - page where the client who presses the `login` button is located
   * @param {string} navigateTo - page previous to redirect
   */
  public async signInWithRedirect(
    origin: string,
    navigateTo: string
  ): Promise<void> {
    await this._oktaAuth
      .signInWithRedirect({ originalUri: origin })
      .then(_ => this._router.navigate([`${navigateTo}`]));
  }

  /**
   * Logout function, remove all information okta
   * @param {string} origin
   */
  public async signOut(origin: string): Promise<void> {
    await this._oktaAuth.signOut();
  }

  /**
   * Return Patron info from Okta
   * @returns Promise<UserClaims>
   */
  public async getUserInfo(): Promise<UserClaims> {
    return await this._oktaAuth.getUser();
  }

  public getAccessToken(): string {
    return this._oktaAuth.getAccessToken()!;
  }
}
