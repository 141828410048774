import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  PatronModel,
  SaleEvent,
  TransactionModel,
  TransactionWrapper,
} from '@core/models';
import {
  ApiException,
  BaseConnectionService,
} from '@core/services/base-connection.service';
import { catchError, map, Subject, tap, throwError } from 'rxjs';
import { PaymentDetails } from '../models/payment-details.model';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseConnectionService {
  private http = inject(HttpClient);
  token: string;
  eventSubject: Subject<SaleEvent> = new Subject<SaleEvent>();

  // Get transaction by ID
  // Devuelve la transacion, junto la tdcTransaction ademas de la informacion del Evento
  getTransactionById(transactionId: string, brainTree = true) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}&braintree=${brainTree}`;
    return this.sendRequest<TransactionWrapper>(this.http, endpoint).pipe(
      tap(data =>
        this.eventSubject.next(data.data?.transaction?.event as SaleEvent)
      )
    );
  }

  // Delete transaction
  // Cancela la transaccion y cancela el cartId
  deleteTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?token=${this.token}`;
    const observable = this.http.delete<
      ApiResponse<Pick<TransactionModel, 'id'>>
    >(endpoint, this.httpOptions);
    return this.handleApiResponse(observable);
  }

  // Hacemos el checkout en Provenue adjuntando los datos de pago. Y patronId de la persona que esta haciendo el pago.
  checkoutTransaction(transactionId: string, payload: PaymentDetails) {
    let {
      accountCredit,
      digitalWallet,
      nonce,
      cardType,
      paymentMethod,
      firstName,
      lastName,
      email,
      address,
    } = payload;
    const { credits, payFull } = accountCredit;
    const endpoint = `${this.apiURL}/transaction/${transactionId}/checkout?token=${this.token}`;
    if (nonce && digitalWallet) {
      nonce = null;
      cardType = digitalWallet.cardType.description;
    }
    const body = {
      payments: {
        cardType: cardType ? cardType.toLocaleLowerCase() : null,
        paymentNonce: nonce,
        paymentMethod,
        digitalWalletId: digitalWallet ? digitalWallet.id : null,
        credit: credits, // dont send pay in full, now is checking in api
      },
      customer: {
        firstName,
        lastName,
        email,
        address,
      },
    };
    return this.sendRequest<TransactionModel>(this.http, endpoint, body);
  }

  postAssociatePatronToCart(
    patronOktaId: string,
    email: string,
    transactionId: number,
    token: string
  ) {
    let endpoint = `${this.apiURL}/transaction/${transactionId}/add/patron?token=${token}`;
    return this.sendRequest<PatronModel | null>(this.http, endpoint, {
      customer: patronOktaId,
      email,
    }).pipe(
      map(obj => {
        if (obj.data === null) {
          throw new ApiException({
            code: '',
            message: 'Patron Not Found',
            data: { isPatronNotFounError: true },
          });
        }

        return obj;
      }),
      catchError(error => {
        console.error('API ERROR: ', error);
        return throwError(() => new ApiException(error.error.error));
      })
    );
  }
}
